import React, { Component } from "react";
import {
  Segment,
  Label,
  Grid,
  Table,
  Header,
  Form,
  Input
} from "semantic-ui-react";

import "./InputScoreConfig.css";

import scoreDefaultMap from "../data/scoreDefaultMap";

class InputScoreConfig extends Component {
  state = {
    scoreConfigDefaultMap: scoreDefaultMap
  };
  handleChange = e => {
    const { target } = e;
    let newScoreConfigDefaultMap = this.state.scoreConfigDefaultMap;
    newScoreConfigDefaultMap.forEach(config => {
      config.rows.forEach(row => {
        row.values.forEach(element => {
          if (element.key === target.name) {
            element.value = target.value;
          }
        });
      });
    });
    this.setState({
      scoreConfigDefaultMap: newScoreConfigDefaultMap
    });
    this.props.inputChange({
      key: target.name,
      value: target.value
    });
  };
  render() {
    const { scoreConfigDefaultMap } = this.state;
    return (
      <Segment padded>
        <Label attached="top" color="black" content="Score Configuration" />
        <Grid doubling stackable columns={3}>
          {scoreConfigDefaultMap.map(config => {
            return (
              <Grid.Column key={config.key}>
                <Header as="h4" content={config.name} textAlign="center" />
                <Table
                  definition
                  celled
                  collapsing
                  unstackable
                  className="ScoreConfig"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell />
                      {config.headers.map(header => (
                        <Table.HeaderCell key={header}>
                          {header}
                        </Table.HeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {config.rows.map(row => {
                      return (
                        <Table.Row key={row.name}>
                          <Table.Cell>{row.name}</Table.Cell>
                          {row.values.map(element => {
                            return (
                              <Table.Cell key={element.key}>
                                <Form.Field
                                  name={element.key}
                                  control={Input}
                                  value={element.value}
                                  onChange={this.handleChange}
                                />
                              </Table.Cell>
                            );
                          })}
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Grid.Column>
            );
          })}
        </Grid>
      </Segment>
    );
  }
}

export default InputScoreConfig;
