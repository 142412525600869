import React, { Component } from 'react';
import { Header, Segment } from 'semantic-ui-react';

class Bundle extends Component {
  render() {
    return (
      <div>
        <Segment>
            <Header as='h3' content='Convert bundle.json' />
            <p>Please Download the bundle.json from <a href="https://sensopro.cloud" target="_blank" rel="noopener noreferrer">sensopro.cloud</a></p>
        </Segment>
      </div>
    );
  }
}

export default Bundle;
