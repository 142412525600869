import React, { Component } from "react";
import { Segment, Label, Form, Input } from "semantic-ui-react";

class InputSensors extends Component {
  handleChange = e => {
    const { target } = e;
    this.props.inputChange({
      key: target.name,
      value: target.value
    });
  };
  render() {
    return (
      <Segment padded>
        <Label attached="top" color="black" content="Sensors" />
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              name="brickMaster"
              control={Input}
              label="Master Brick"
              placeholder="AB12..."
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              name="brickletAccelerometerLeft"
              control={Input}
              label="Accelerometer LEFT"
              placeholder="AB12..."
              onChange={this.handleChange}
            />
            <Form.Field
              name="brickletAccelerometerRight"
              control={Input}
              label="Accelerometer RIGHT"
              placeholder="AB12..."
              onChange={this.handleChange}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default InputSensors;
