import React, { Component } from "react";
import { Segment, Label, Input } from "semantic-ui-react";

class SelectFile extends Component {
  handleChange = selectorFiles => {
    var reader = new FileReader();
    reader.onload = event => {
      var jsonObj = JSON.parse(event.target.result);
      this.props.onReadJson(jsonObj);
    };
    reader.readAsText(selectorFiles[0]);
  };

  render() {
    return (
      <Segment padded>
        <Label attached="top" color="black" content="Select File" />
        <Input type="file" onChange={e => this.handleChange(e.target.files)} />
      </Segment>
    );
  }
}

export default SelectFile;
