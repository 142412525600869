import React, { Component } from 'react';
import { Header, Container, Menu } from 'semantic-ui-react';

import Trainer from './pages/Trainer';
import Bundle from './pages/Bundle';

class App extends Component {
  state = { 
    activeItem: 'trainer'
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state
    let activePage = <Trainer />;
    if (activeItem === 'bundle') {
      activePage = <Bundle />;
    } 
    return (
      <div>
        <Header as='h1' content='SensoPro Challenge Converter' textAlign='center' />
        <Container>
          <Menu stackable pointing>
            <Menu.Item
              name='trainer'
              active={activeItem === 'trainer'}
              onClick={this.handleItemClick}
            />
            <Menu.Item
              name='bundle'
              active={activeItem === 'bundle'}
              onClick={this.handleItemClick}
            />
          </Menu>
         {activePage}
        </Container>
      </div>
    );
  }
}

export default App;
