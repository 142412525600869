import React, { Component } from "react";
import { Header, Segment } from "semantic-ui-react";

import SelectFile from "../components/SelectFile";
import InfoTable from "../components/InfoTable";
import InputSensors from "../components/InputSensors";
import InputScoreConfig from "../components/InputScoreConfig";
import TrainerExport from "../components/TrainerExport";

import scoreDefaultConfig from '../data/scoreDefaultConfig';

class Trainer extends Component {
  state = {
    trainer: null,
    infoRows: null,
    sensors: {
      brickMaster: "",
      brickletAccelerometerLeft: "",
      brickletAccelerometerRight: ""
    },
    scoreConfiguration: scoreDefaultConfig
  };

  onReadTrainer = trainer => {
    let infoRows = [];
    infoRows.push({ key: "ID", value: trainer.id });
    infoRows.push({ key: "Name", value: trainer.name });
    infoRows.push({ key: "Language", value: trainer.language });
    this.setState({ trainer, infoRows });
  };
  sensorInputChange = element => {
    this.setState({
      sensors: {
        ...this.state.sensors,
        [element.key]: element.value
      }
    });
  };

  scoreInputChange = element => {
    if (element.key.startsWith("pol_h_")) {
      this.setState({
        scoreConfiguration: this.generatePrecisionScoreConfigObject(
          "precision-one-leg",
          "health",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("pol_f_")) {
      this.setState({
        scoreConfiguration: this.generatePrecisionScoreConfigObject(
          "precision-one-leg",
          "fitness",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("pol_p_")) {
      this.setState({
        scoreConfiguration: this.generatePrecisionScoreConfigObject(
          "precision-one-leg",
          "pro",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("pl_h_")) {
      this.setState({
        scoreConfiguration: this.generatePrecisionScoreConfigObject(
          "precision-lunges",
          "health",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("pl_f_")) {
      this.setState({
        scoreConfiguration: this.generatePrecisionScoreConfigObject(
          "precision-lunges",
          "fitness",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("pl_p_")) {
      this.setState({
        scoreConfiguration: this.generatePrecisionScoreConfigObject(
          "precision-lunges",
          "pro",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("ps_p_")) {
      this.setState({
        scoreConfiguration: this.generatePrecisionScoreConfigObject(
          "precision-sideways",
          "pro",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("sq_h_")) {
      this.setState({
        scoreConfiguration: this.generateSymmetryScoreConfigObject(
          "symmetry-squat",
          "health",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("sq_f_")) {
      this.setState({
        scoreConfiguration: this.generateSymmetryScoreConfigObject(
          "symmetry-squat",
          "fitness",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("sq_p_")) {
      this.setState({
        scoreConfiguration: this.generateSymmetryScoreConfigObject(
          "symmetry-squat",
          "pro",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("sb_h_")) {
      this.setState({
        scoreConfiguration: this.generateSymmetryScoreConfigObject(
          "symmetry-bouncing",
          "health",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("sb_f_")) {
      this.setState({
        scoreConfiguration: this.generateSymmetryScoreConfigObject(
          "symmetry-bouncing",
          "fitness",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("sb_p_")) {
      this.setState({
        scoreConfiguration: this.generateSymmetryScoreConfigObject(
          "symmetry-bouncing",
          "pro",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("ss_h_")) {
      this.setState({
        scoreConfiguration: this.generateSymmetryScoreConfigObject(
          "symmetry-sideway",
          "health",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("ss_f_")) {
      this.setState({
        scoreConfiguration: this.generateSymmetryScoreConfigObject(
          "symmetry-sideway",
          "fitness",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("sw_h_")) {
      this.setState({
        scoreConfiguration: this.generateSymmetryScoreConfigObject(
          "symmetry-wave",
          "health",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("sw_f_")) {
      this.setState({
        scoreConfiguration: this.generateSymmetryScoreConfigObject(
          "symmetry-wave",
          "fitness",
          element,
          this.state.scoreConfiguration
        )
      });
    } else if (element.key.startsWith("sw_p_")) {
      this.setState({
        scoreConfiguration: this.generateSymmetryScoreConfigObject(
          "symmetry-wave",
          "pro",
          element,
          this.state.scoreConfiguration
        )
      });
    }
  };

  generatePrecisionScoreConfigObject = (
    key,
    category,
    element,
    scoreConfiguration
  ) => {
    return {
      ...scoreConfiguration,
      [key]: {
        ...scoreConfiguration[key],
        [category]: {
          medial100Score: element.key.endsWith("medial100")
            ? element.value
              ? parseInt(element.value)
              : 0
            : scoreConfiguration[key][category].medial100Score,
          medial80Score: element.key.endsWith("medial80")
            ? element.value
              ? parseInt(element.value)
              : 0
            : scoreConfiguration[key][category].medial80Score,
          medial20Score: element.key.endsWith("medial20")
            ? element.value
              ? parseInt(element.value)
              : 0
            : scoreConfiguration[key][category].medial20Score,
          lateral100Score: element.key.endsWith("lateral100")
            ? element.value
              ? parseInt(element.value)
              : 0
            : scoreConfiguration[key][category].lateral100Score,
          lateral80Score: element.key.endsWith("lateral80")
            ? element.value
              ? parseInt(element.value)
              : 0
            : scoreConfiguration[key][category].lateral80Score,
          lateral20Score: element.key.endsWith("lateral20")
            ? element.value
              ? parseInt(element.value)
              : 0
            : scoreConfiguration[key][category].lateral20Score
        }
      }
    };
  };

  generateSymmetryScoreConfigObject = (
    key,
    category,
    element,
    scoreConfiguration
  ) => {
    return {
      ...scoreConfiguration,
      [key]: {
        ...scoreConfiguration[key],
        [category]: {
          difference100Score: element.key.endsWith("difference100")
            ? element.value
              ? parseInt(element.value)
              : 0
            : scoreConfiguration[key][category].difference100Score,
          difference80Score: element.key.endsWith("difference80")
            ? element.value
              ? parseInt(element.value)
              : 0
            : scoreConfiguration[key][category].difference80Score,
          difference20Score: element.key.endsWith("difference20")
            ? element.value
              ? parseInt(element.value)
              : 0
            : scoreConfiguration[key][category].difference20Score
        }
      }
    };
  };

  render() {
    const { infoRows, trainer, sensors, scoreConfiguration } = this.state;
    return (
      <div>
        <Segment>
          <Header as="h3" content="Convert trainer.json" />
          <SelectFile onReadJson={this.onReadTrainer} />
          <InfoTable rows={infoRows} />
          <InputSensors inputChange={this.sensorInputChange} />
          <InputScoreConfig inputChange={this.scoreInputChange} />
          <TrainerExport
            trainer={trainer}
            sensors={sensors}
            scoreConfiguration={scoreConfiguration}
          />
        </Segment>
      </div>
    );
  }
}

export default Trainer;
