import React, { Component } from "react";
import { Header, Segment, Label, Table } from "semantic-ui-react";

class InfoTable extends Component {
  static defaultProps = {
    rows: null
  };

  render() {
    const { rows } = this.props;
    if (!rows) {
      return (
        <Segment padded>
          <Label attached="top" color="black" content="Info" />
          <p>No Info availbale. Please select a trainer.json.</p>
        </Segment>
      );
    }
    return (
      <Segment padded>
        <Label attached="top" color="black" content="Info" />
        <Table basic="very" celled collapsing>
          <Table.Body>
            {rows.map(element => {
              return (
                <Table.Row key={element.key}>
                  <Table.Cell>
                    <Header as="h4">{element.key}</Header>
                  </Table.Cell>
                  <Table.Cell>{element.value}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default InfoTable;
