export function downloadObjectAsJson(exportObj, exportName) {
  var blob = new Blob([JSON.stringify(exportObj, null, 4)], {
    type: "text/json;charset=utf-8;"
  });
  var url = URL.createObjectURL(blob);
  var downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", url);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}
