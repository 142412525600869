import React, { Component } from "react";
import { Segment, Label, Button } from "semantic-ui-react";

import { downloadObjectAsJson } from '../utils/donwload';

class Preview extends Component {
  static defaultProps = {
    trainer: null,
    sensors: null,
    scoreConfiguration: null
  };

  convertTrainer = () => {
    const { trainer, sensors, scoreConfiguration } = this.props;
    if (!trainer || !sensors || !scoreConfiguration) {
      return;
    }
    let newTrainer = trainer;
    newTrainer.sensors = {
      brickMaster: sensors.brickMaster,
      brickletAccelerometer: {
        left: sensors.brickletAccelerometerLeft,
        right: sensors.brickletAccelerometerRight
      }
    };
    newTrainer.scoreConfiguration = scoreConfiguration;

    downloadObjectAsJson(newTrainer, "trainer-challenge");
  };

  render() {
    const { trainer, sensors, scoreConfiguration } = this.props;
    if (!trainer || !sensors || !scoreConfiguration) {
      return (
        <Segment padded>
          <Label attached="top" color="black" content="Export" />
          <p>Export not possible. Please select a trainer.json.</p>
        </Segment>
      );
    }
    return (
      <Segment padded>
        <Label attached="top" color="black" content="Export" />
        <Button
          content="Export new trainer.json"
          onClick={this.convertTrainer}
        />
      </Segment>
    );
  }
}

export default Preview;
